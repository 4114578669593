import { isObject } from 'lodash';

export const parseServerResponseIntoMessage = (resOrErr) => {
  // Check if is a successsful server response with body containing Message
  if (resOrErr.data && typeof resOrErr.data === 'object') {
    return resOrErr.data;
  } // Check if error and contains response data with body containing Message
  else if (resOrErr.response && resOrErr.response.data && typeof resOrErr.response.data === 'object') {
    return resOrErr.response.data;
  }
  // Otherwise create Message with any error message we can find
  const msg = resOrErr.message || resOrErr.statusMessage || 'Empty message!';
  return { msgCode: 'error', msg };
};

export const parseTimestampToDate = (timestampLike) => {
  if (!timestampLike) return undefined;

  if (timestampLike.toDate) {
    // toDate method is found in Firebase Timestamps
    return timestampLike.toDate();
  } else if (isObject(timestampLike) && timestampLike._seconds && timestampLike._nanoseconds) {
    // Is a timestamp as object
    return new Date(parseInt(timestampLike._seconds * 1000) + parseInt(timestampLike._nanoseconds / 1000000));
  } else {
    // If not a timestamp, should be a time since epoch in millis
    return new Date(parseInt(timestampLike));
  }
};
