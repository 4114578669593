import React, { useState } from 'react';
import {
  ListItem,
  List,
  ListItemSecondaryAction,
  ListItemText,
  Container,
  IconButton,
  Typography,
  Tooltip,
  ListSubheader,
  Fab,
  makeStyles,
  useTheme,
  ListItemAvatar,
  Avatar,
  Checkbox,
  lighten,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ClearIcon from '@material-ui/icons/Clear';
import { useSelector, useDispatch } from 'react-redux';
import { isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { updateUi } from '../../redux/actions/uiActions';
import { actions } from '../../redux/actions/actionsHandler';
import { useIntl } from 'react-intl';
import { isAssistantProf, isKeyUserProf, isModeratorProf } from '../../util/helpers/user';
import { getInventoryTasksPath } from '../../util/helpers/path';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ReplayIcon from '@material-ui/icons/Replay';
import InfoIcon from '@material-ui/icons/Info';
import { getTaskExampleAddresses } from '../../util/helpers/tasks';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  container: { ...theme.container(2), marginLeft: 0 },
  subtitle: {
    margin: theme.spacing(1),
  },
  listContainer: {
    maxWidth: '800px',
    marginBottom: theme.spacing(2),
  },
  listItem: {
    paddingRight: theme.spacing(25),
    margin: theme.spacing(2),
  },
  itemText: {
    margin: theme.spacing(1, 1),
  },
  stateDescriptionText: {
    textAlign: 'right',
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    padding: theme.spacing(0.5),
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },
  skeletonItemText: {
    minWidth: theme.spacing(60),
    margin: theme.spacing(0, 1),
    height: theme.spacing(8),
  },
  tooltipRoot: {
    maxWidth: 500,
  },
}));

const taskStates = { PENDING: 'PENDING', STARTED: 'STARTED', UPLOADING: 'UPLOADING', COMPLETED: 'COMPLETED' };

const ListItemLink = (props) => {
  const { linkEnabled, ...rest } = props;
  return <ListItem button component={props.linkEnabled ? Link : 'span'} {...rest} />;
};

export default function InventoryTasks(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [selection, setSelection] = useState({});

  const domain = props.match.params.domain;
  const company = props.match.params.company;

  const textColorByState = {
    PENDING: theme.palette.warning.main,
    STARTED: theme.palette.info.main,
    UPLOADING: theme.palette.info.main,
    COMPLETED: theme.palette.success.main,
  };

  const getTaskImageByState = (state, props) => {
    const style = {
      color: theme.palette.white,
      backgroundColor: textColorByState[state],
    };

    let icon;
    switch (state) {
      case taskStates.PENDING:
        icon = (props) => <HourglassEmptyIcon {...props} />;
        break;
      case taskStates.STARTED:
        icon = (props) => <PlayArrowIcon {...props} />;
        break;
      case taskStates.UPLOADING:
        icon = (props) => <CloudUploadIcon {...props} />;
        break;
      case taskStates.COMPLETED:
        icon = (props) => <DoneAllIcon {...props} />;
        break;
      default:
        icon = (_props) => null;
        break;
    }
    return { style, icon };
  };

  const { profile, inventoryTasks } = useSelector((s) => ({
    profile: s.firebase.profile,
    inventoryTasks: s.firestore.data[`inventoryTasks${domain}`],
  }));
  useEffect(() => clearSelection(), [inventoryTasks]);

  useFirestoreConnect([
    {
      collection: isLoaded(profile) && getInventoryTasksPath(company, domain),
      storeAs: `inventoryTasks${domain}`,
      orderBy: ['createdDate', 'desc'],
      limit: 100,
    },
  ]);

  const isTaskDeletable = (taskId) => {
    if (!isEmpty(inventoryTasks)) {
      const task = inventoryTasks[taskId];
      return task.state !== taskStates.STARTED;
    }
    return false;
  };

  const showRemoveTaskDialogConfirmation = () => {
    if (!hasSelection) return;

    const taskIds = [
      ...Object.entries(selection)
        .filter(([k, v]) => !!v)
        .map(([k, v]) => k),
    ];

    dispatch(
      updateUi({
        dialog: {
          title: intl.formatMessage({ id: 'inventoryTasks.removeTaskTitle' }),
          message: intl.formatMessage({ id: 'inventoryTasks.removeTasksMsg' }),
          confirmAction: actions.REMOVE_INVENTORY_TASK,
          actionData: { taskIds, domain, company },
          useCheckbox: true,
          checkboxState: false,
          checkboxMessage: <b>{intl.formatMessage({ id: `inventoryTasks.taskRemovalCheck` })}</b>,
          show: true,
        },
      })
    );
  };

  const showResetTaskDialogConfirmation = (taskId) => {
    const task = inventoryTasks[taskId];
    if (!task) {
      return;
    }

    dispatch(
      updateUi({
        dialog: {
          title: intl.formatMessage({ id: 'inventoryTasks.restartTaskTitle' }),
          message: intl.formatMessage(
            { id: 'inventoryTasks.restartTaskMsg' },
            { name: <b key="mub0">{task.name}</b>, nl: <br key="munl0" /> }
          ),
          confirmAction: actions.RESTART_INVENTORY_TASK,
          actionData: { taskId, domain, company },
          show: true,
        },
      })
    );
  };

  const showTaskNotCompletedSnack = () => {
    dispatch(
      updateUi({
        snackbar: {
          message: intl.formatMessage({ id: 'inventoryTasks.notCompleted' }),
          severity: 'error',
          duration: 1000,
          show: true,
        },
      })
    );
  };

  const handleCheckboxChange = (taskId) => (event) => {
    if (isTaskDeletable(taskId)) {
      setSelection({ ...selection, [taskId]: event.target.checked });
    }
  };

  const hasSelection = Object.values(selection).some((e) => e === true);

  const isAssistantOrMod = isAssistantProf(profile) || isModeratorProf(profile) || isKeyUserProf(profile);

  const selectAll = () => {
    if (!isEmpty(inventoryTasks)) {
      const newSelection = {};
      Object.keys(inventoryTasks).forEach((k) => (newSelection[k] = isTaskDeletable(k)));
      setSelection(newSelection);
    }
  };

  const clearSelection = () => setSelection({});

  return (
    <Container className={classes.container}>
      <Typography variant="h4" style={theme.text.title}>
        {intl.formatMessage({ id: 'inventoryTasks.title' }, { domain })}
      </Typography>
      <Typography variant="subtitle1" style={theme.text.subtitle}>
        {intl.formatMessage({ id: 'inventoryTasks.subtitle' })}
      </Typography>
      <List
        className={classes.listContainer}
        subheader={<ListSubheader>{intl.formatMessage({ id: 'inventoryTasks.listHeader' })}</ListSubheader>}
      >
        {isAssistantOrMod && (
          <ListItem style={{ paddingRight: 0 }}>
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              aria-label="add"
              component={Link}
              to={`tasks/create`}
            >
              <AddCircleIcon style={{ marginRight: theme.spacing(1) }} />
              {intl.formatMessage({ id: 'inventoryTasks.newTask' })}
            </Fab>

            {isLoaded(inventoryTasks) && !isEmpty(inventoryTasks) ? (
              <>
                <Tooltip
                  edge="end"
                  style={{ marginLeft: 'auto' }}
                  title={
                    <Typography variant="subtitle2">
                      {intl.formatMessage({
                        id: hasSelection ? 'inventoryTasks.clearSelection' : 'inventoryTasks.selectAll',
                      })}
                    </Typography>
                  }
                >
                  <IconButton
                    style={{ color: theme.palette.info.main, marginLeft: 'auto' }}
                    onClick={hasSelection ? clearSelection : selectAll}
                  >
                    {hasSelection ? <ClearIcon /> : <SelectAllIcon />}
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={
                    <Typography variant="subtitle2">
                      {intl.formatMessage({ id: 'inventoryTasks.removeSelected' })}
                    </Typography>
                  }
                >
                  <span>
                    <IconButton
                      disabled={!hasSelection}
                      aria-label={intl.formatMessage({ id: 'remove' })}
                      style={{
                        color: hasSelection ? theme.palette.error.main : theme.palette.other.grey.light,
                      }}
                      onClick={() => showRemoveTaskDialogConfirmation()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            ) : null}
          </ListItem>
        )}
        {isLoaded(inventoryTasks) ? (
          !isEmpty(inventoryTasks) ? (
            Object.keys(inventoryTasks).map((taskId, index) => {
              const task = inventoryTasks[taskId];

              if (!task) return null;

              const taskAvatarData = getTaskImageByState(task.state);
              const taskIsComplete = task.state === taskStates.COMPLETED;
              const taskIsPending = task.state === taskStates.PENDING;
              const taskIsStarted = task.state === taskStates.STARTED;

              return (
                <ListItemLink
                  linkEnabled={taskIsComplete}
                  key={taskId}
                  alignItems="flex-start"
                  className={classes.listItem}
                  to={`/company/${company}/domains/${domain}/sessions/${task.name}`}
                  onClick={taskIsComplete ? null : showTaskNotCompletedSnack}
                  style={{
                    backgroundColor: selection[taskId] ? lighten(theme.palette.secondary.light, 0.75) : 'transparent',
                  }}
                >
                  <ListItemAvatar style={{ margin: 0 }}>
                    <Avatar alt={intl.formatMessage({ id: 'altInventoryTaskStateImage' })} style={taskAvatarData.style}>
                      {taskAvatarData.icon({ fontSize: 'large' })}
                    </Avatar>
                  </ListItemAvatar>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      marginRight: theme.spacing(5),
                    }}
                  >
                    <ListItemText className={classes.itemText} primary={task.name} />
                    <ListItemText
                      className={clsx(classes.itemText, classes.stateDescriptionText)}
                      primary={intl.formatMessage({
                        id: `inventoryTasks.${task.state}`,
                        defaultMessage: intl.formatMessage({ id: 'inventoryTasks.unknownState' }),
                      })}
                      style={{ color: textColorByState[task.state] }}
                    />
                  </div>
                  {isAssistantOrMod && (
                    <ListItemSecondaryAction>
                      <Tooltip
                        title={
                          <Typography variant="subtitle2">
                            {intl.formatMessage({
                              id: taskIsPending ? 'inventoryTasks.cannotRestartPending' : 'restart',
                            })}
                          </Typography>
                        }
                      >
                        <span>
                          <IconButton
                            style={{
                              color: taskIsPending ? theme.palette.other.grey.main : theme.palette.info.main,
                            }}
                            disabled={taskIsPending}
                            onClick={() => showResetTaskDialogConfirmation(taskId)}
                            edge="start"
                            aria-label={intl.formatMessage({
                              id: taskIsPending ? 'inventoryTasks.cannotRestartPending' : 'restart',
                            })}
                          >
                            <ReplayIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      {task.exampleAddresses && (
                        <Tooltip
                          classes={{ tooltip: classes.tooltipRoot }}
                          title={
                            <Typography variant="subtitle2">
                              {intl.formatMessage(
                                { id: 'inventoryTasks.taskSummary' },
                                {
                                  exampleAddresses: getTaskExampleAddresses(task),
                                  count: task.addressesCount,
                                }
                              )}
                            </Typography>
                          }
                        >
                          <IconButton
                            aria-label={intl.formatMessage({ id: 'info' })}
                            style={{ color: theme.palette.info.main }}
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {/* Selection checkbox */}
                      <Checkbox
                        edge="end"
                        onChange={handleCheckboxChange(taskId)}
                        disabled={taskIsStarted}
                        checked={!!selection[taskId]}
                        color="secondary"
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItemLink>
              );
            })
          ) : (
            <ListItem key="emptyTasks" alignItems="flex-start" className={classes.listItem}>
              <ListItemText
                id="emptyTasks"
                className={classes.itemText}
                primary={intl.formatMessage({ id: 'inventoryTasks.emptyTasks' })}
              />
            </ListItem>
          )
        ) : (
          [0, 1, 2].map((i) => (
            <Container className={clsx(classes.listItem, classes.skeletonContainer)} key={`skel${i}`}>
              <Skeleton variant="text" animation="wave" className={classes.skeletonItemText} />
            </Container>
          ))
        )}

        <ListItem />
      </List>
    </Container>
  );
}
