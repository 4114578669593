import { getAllFromDividedQuery } from '../../util/helpers/redux';
import { pickBy } from 'lodash';
import { isLoaded } from 'react-redux-firebase';
import { getFirst } from '../../util/helpers/other';

export const selectCompanyData = (company, state) => {
  const { isLoaded: companiesLoaded, data } = getAllFromDividedQuery(state.firestore, "companiesData")
  const companyData = isLoaded ? data?.[company] : null
  return { isLoaded: companiesLoaded && isLoaded(companyData), companyData }
};

export const selectMyProfile = (state) => {
  const profile = state.firebase.profile
  return { isLoaded: isLoaded(profile), profile }
};

export const selectAllLicenses = (state) => {
  const { isLoaded: licLoaded, data: licenses } = getAllFromDividedQuery(
    state.firestore,
    'licenses'
  );
  return { isLoaded: licLoaded, data: licLoaded ? licenses || {} : {} };
};

export const selectAllApiKeys = (state) => {
  const { isLoaded, data: licenses } = getAllFromDividedQuery(state.firestore, 'APIKeys');
  return { isLoaded, data: isLoaded ? licenses || {} : {} };
};

export const selectLicensesPerDomain = (domain, state) => {
  const { isLoaded: licLoaded, data: licenses } = selectAllLicenses(state);

  const domainLicenses = licLoaded
    ? pickBy(licenses, (value) => value.authDomains.includes(domain))
    : {};
  return { isLoaded: licLoaded, data: domainLicenses };
};

export const selectAllUsers = (state, ...exceptUids) => {
  let ret = getAllFromDividedQuery(state.firestore, 'userProfiles');
  const { isLoaded, data: userProfiles } = ret;
  if (exceptUids && exceptUids.length && isLoaded && userProfiles) {
    return { isLoaded, data: pickBy(userProfiles, (_, key) => !exceptUids.includes(key)) };
  }
  return ret;
};

export const selectParentCandidateModerators = (state, company, domain) => {
  const selectionData = selectAllUsers(state);
  const modProfiles = filterModerators(
    selectionData,
    (p) => p.company === company && p.authDomains && p.authDomains.includes(domain)
  );
  return { isLoaded: selectionData.isLoaded, data: modProfiles };
};

export const selectModerators = (state, { exceptions, filter } = {}) => {
  const selectionData = selectAllUsers(state, ...(exceptions || []));
  const modProfiles = filterModerators(selectionData, filter);
  return { isLoaded: selectionData.isLoaded, data: modProfiles };
};

export const selectCompanies = (state) => {
  const company = state.firebase.profile.company;
  return Array.isArray(company) ? company.sort() : [];
};

export const selectAllDomains = (state) => selectCompaniesDomains(selectCompanies(state), state);

export const selectCompaniesDomains = (companies, state) => {
  const data = {}; // Domains list by company
  for (const company of companies) {
    const ret = selectCompanyDomains(company, state);
    if (!ret.isLoaded) return ret;
    data[company] = ret.data;
  }
  return { isLoaded: true, data };
};

export const selectCompanyDomains = (company, state) => {
  const domains = state.firestore.data[`${company}-authDomains`];
  // Ignore data if not loaded completely
  if (!isLoaded(domains)) return { isLoaded: false, data: [] };
  return { isLoaded: true, data: domains ? Object.keys(domains) : [] };
};

export const selectDomainSettings = (company, domain, state) => {
  const settings = state.firestore.data[`${company}-sett${domain}`];
  // Ignore data if not loaded completely
  if (!isLoaded(settings)) return { isLoaded: false, data: {} };
  return { isLoaded: true, data: settings ? getFirst(settings) : {} };
};

export const selectCustomDomainSettings = (company, domain, session, state) => {
  const settings = state.firestore.data[`${company}-sett${domain}-${session}`];
  // Ignore data if not loaded completely
  if (!isLoaded(settings)) return { isLoaded: false, data: undefined };
  return { isLoaded: true, data: settings ? getFirst(settings) : undefined };
};

export const selectDomainSessions = (company, domain, state) => {
  const domainSessions = state.firestore.data[`sess${company}${domain}`];
  // Ignore data if not loaded completely
  if (!isLoaded(domainSessions)) return { isLoaded: false, data: {} };
  return { isLoaded: true, data: domainSessions || {} };
};

export const filterModerators = ({ isLoaded, data }, filter) => {
  return isLoaded
    ? pickBy(
      data,
      (profile) => profile && profile.moderator === true && (filter ? filter(profile) : true)
    )
    : {};
};

export function groupByCompany({ isLoaded, data }) {
  const dataByCompany = {};
  if (isLoaded) {
    Object.entries(data).forEach(([key, obj]) => {
      if (obj) {
        const company = obj.company;
        if (typeof dataByCompany[company] === 'object') {
          dataByCompany[company][key] = obj;
        } else {
          dataByCompany[company] = { [key]: obj };
        }
      }
    });
  }
  return dataByCompany;
}
