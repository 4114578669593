import axios from 'axios';
import history from '../../history';
import { getInventoryTasksPath } from '../../util/helpers/path';
import { ui } from '../types';
import { getSnackbar } from './uiActions';

export const removeSessions =
  (data) =>
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const { company, domain, session } = data;
    if (!Array.isArray(session) || !session.length || !company || !domain) {
      return;
    }

    dispatch({
      type: ui.UPDATE_UI,
      data: {
        backdrop: {
          show: true,
          message: {
            msgCode: 'domain.removingSessions',
            msg: 'Removing session(s)...',
          },
        },
      },
    });

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/sessions`,
          method: 'delete',
          headers: { Authorization: `Bearer ${token}`, company },
          withCredentials: true,
          params: {
            domain,
            session,
          },
        })
      )
      .then(() => {
        dispatch({
          type: ui.UPDATE_UI,
          data: {
            snackbar: {
              message: {
                msgCode: 'domain.sessionsRemoved',
                msg: 'Session(s) removed',
              },
              duration: 5000,
              severity: 'success',
              show: true,
            },
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: {
            snackbar: getSnackbar(err),
          },
        });
      });
  };


export const triggerSessionPush =
// data = { domain, session: session name}
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI_NO_RESET,
      data: {
        backdrop: {
          show: true,
          message: { msgCode: 'manDom.trignSessionPush', msg: 'Triggering session push' },
        },
        dialog: {
          show: false,
        },
      },
    });

    const company = data.company;
    delete data.company;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/triggerSessionPush`,
          method: 'post',
          params: { 
            ...data, company, isForcedPush: true,
            pushUrl: `${process.env.REACT_APP_API_ENTRY_POINT}/sessionPush`
           },
          headers: { Authorization: `Bearer ${token}`, company },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: {
            snackbar: {
              message: {
                msgCode: 'domain.pushTriggered',
                msg: 'Push has been triggered!',
              },
              duration: 5000,
              severity: 'success',
              show: true,
            },
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const restartTask =
  (data) =>
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const { company, domain, taskId } = data;
    if (!taskId || !company || !domain) {
      return;
    }

    dispatch({
      type: ui.UPDATE_UI,
      data: {
        backdrop: {
          show: true,
          message: {
            msgCode: 'inventoryTasks.restartingTask',
            msg: 'Restarting task...',
          },
        },
      },
    });

    getFirestore()
      .doc(`${getInventoryTasksPath(company, domain)}/${taskId}`)
      .update({
        state: 'PENDING',
      })
      .then(() => {
        dispatch({
          type: ui.UPDATE_UI,
          data: {
            snackbar: {
              message: {
                msgCode: 'inventoryTasks.taskRestarted',
                msg: 'Task has been restarted!',
              },
              duration: 5000,
              severity: 'success',
              show: true,
            },
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: {
            snackbar: getSnackbar(err),
          },
        });
      });
  };

export const removeTask =
  (data) =>
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const { company, domain, taskIds } = data;
    if (!Array.isArray(taskIds) || !taskIds.length || !company || !domain) {
      return;
    }

    dispatch({
      type: ui.UPDATE_UI,
      data: {
        backdrop: {
          show: true,
          message: {
            msgCode: 'inventoryTasks.removingTask',
            msg: 'Removing task...',
          },
        },
      },
    });

    const fs = getFirestore();
    const batch = fs.batch();
    taskIds.forEach((task) =>
      batch.delete(fs.doc(`${getInventoryTasksPath(company, domain)}/${task}`))
    );
    batch
      .commit()
      .then(() => {
        dispatch({
          type: ui.UPDATE_UI,
          data: {
            snackbar: {
              message: {
                msgCode: 'inventoryTasks.taskRemoved',
                msg: 'Tasks removed',
              },
              duration: 5000,
              severity: 'success',
              show: true,
            },
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: {
            snackbar: getSnackbar(err),
          },
        });
      });
  };

export const createTask =
  (data) =>
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const { domain, name, inputFileData, company } = data;

    if (!domain || !inputFileData) {
      return;
    }

    dispatch({
      type: ui.UPDATE_UI,
      data: {
        loadingButton: {
          isCreateTaskLoading: true,
        },
      },
    });

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/tasks`,
          method: 'post',
          headers: {
            Authorization: `Bearer ${token}`,
            // eslint-disable-next-line
            ['Content-Type']: inputFileData.type,
            company,
          },
          withCredentials: true,
          params: {
            domain,
            name,
          },
          data: inputFileData.arrayBuffer,
        })
      )
      .then(() => {
        dispatch({
          type: ui.UPDATE_UI,
          data: {
            snackbar: {
              message: {
                msgCode: 'createInventoryTask.taskCreated',
                msg: 'Tasks created!',
              },
              duration: 3000,
              severity: 'success',
              show: true,
            },
          },
        });

        history.goBack();
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: {
            snackbar: getSnackbar(err),
          },
        });
      });
  };
