import { isObject } from 'lodash';

/** Returns the function name */
export function nameOf(f) {
  f.toString().replace(/[ |()=>]/g, '');
}

/**
 * Returns the first element of the object
 *
 * @param {Object} object object to fetch from
 * @returns the first element of the object or null
 */
export function getFirst(object) {
  return isObject(object) ? Object.values(object)[0] : null;
}

export function buildLicenseDescription(license, id, intl, cropLimit) {
  const _id = cropLimit ? id.substr(0, cropLimit) : id;
  return `${license.description} - S/N: ${_id}${cropLimit ? '...' : ''} - exp: ${intl.formatDate(
    new Date(license.expDate.seconds * 1000)
  )}`;
}

export function buildShortLicenseDescription(license, id, cropLimit = 4) {
  const _id = cropLimit ? id.substr(0, cropLimit) : id;
  return `${license.description} - ${_id}${cropLimit ? '...' : ''}`;
}

export function buildMifDescription(mif, intl) {
  let desc = mif.field;
  if (mif.useCheckField) {
    desc = desc.concat(` | ${intl.formatMessage({ id: 'checkField' })}: ${mif.checkField}`);
    if (mif.maxAttempts > 0) {
      desc = desc.concat(
        ` | ${intl.formatMessage({ id: 'dSett.repeat' }, { maxAttempts: mif.maxAttempts })}`
      );
    }
  }
  return desc;
}

/** Fallback in case navigator.clipboard is not available */
export function fallbackCopyTextToClipboard(text, callback) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    if (successful && callback) {
      callback();
    }
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text, callback) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, callback);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      if (callback) callback();
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}

export const sortObjectByKeys = (obj) =>
  Object.keys(obj)
    .sort()
    .reduce((o, key) => {
      o[key] = obj[key];
      return o;
    }, {});

export const sortObjectByValues = (obj) =>
  Object.entries(obj)
    .sort(([k1, v1], [k2, v2]) => v1.localeCompare(v2))
    .reduce((o, [key, value]) => {
      o[key] = value;
      return o;
    }, {});
